import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------
faker.setLocale('ko');

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.lastName() + faker.name.firstName(),
  source: faker.company.name(),
  location: faker.address.city(),
  status: sample(['활성화', '비활성화']),
  period: sample([
    '5m',
    '10m',
    '30m',
    '60m',
  ]),
}));

export default users;
